import React from "react";
import { l10n } from "../../common/constants";
import { useSelector } from "react-redux";
import { getDate, getTime } from "../../common/helpers";
import { Accordion, Card, Spinner } from "react-bootstrap";

export default function MyTickets(props) {
    const { language } = useSelector((state) => ({
        language: state.userReducer.language,
    }));

  function capitalizeFirstLetter(string) {
    if(string){
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else return null;
  }

  function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

  // Redirect user to ticket page
  const handleTicketClick = eventCode => {
    window.location.href = `https://accounts.gms.church/:register_success?event_code=${eventCode}`;
  }

  return (
    <Accordion className='fixed-bottom' style={accordionStyle}>
      <Card style={cardStyle}>
    
        {props.tickets.sort((a, b) => { return a.sub_event.start_date > b.sub_event.start_date }).map((ticket, i) => 
          {
            let extras = null;
            if(isJsonString(ticket.extras)){
              extras = JSON.parse(ticket.extras)
            } else if(ticket.extras){
              extras = ticket.extras
            }
            const {exclusive} = ticket.sub_event.main_event
          return (
          <div key={`ticket-${i}`}>
          {/* Header - 'Tiket Saya / My Ticket' */}
          {i===0 ? (
          <Accordion.Toggle as={Card.Header} eventKey={`${i}`} style={ticketHeaderStyle}>
            <p style={ticketHeaderTextStyle}>{l10n['myTicket'][language]}</p>
          </Accordion.Toggle>
          ) : null }
          {/* Body - Ticket List*/}
          <Accordion.Collapse eventKey={`${i}`}>
            <div className="card-block">
              <div className="card-body d-flex justify-content-between align-items-center" style={cardBodyStyle}>
                <div style={cardInnerStyle}>
                  {/* Badge - Notify user ticket for today */}
                  {getDate(new Date()) === getDate(ticket.sub_event.start_date) ? <Spinner animation='grow' variant='danger' size='sm' style={badgeStyle} /> : null}
                  <div onClick={() => handleTicketClick(ticket.sub_event.main_event.code)} className="row">
                    <div className="col-10">
                    <p className="card-text" style={{ marginBottom: '0px', fontSize: '14px', color: '#231f20', fontWeight: 'bold' }}>
                    {extras && exclusive === "christmascelebration" && parseInt(extras.forvip) === 1 ? ticket.sub_event.main_event.referencename : ticket.sub_event.main_event.name}
                    </p>
                    <p className="card-text" style={{ marginBottom: '0px' }}><small style={{ color: '#231f20' }}>{getDate(ticket.sub_event.start_date)} - {getTime(ticket.sub_event.start_date)}</small></p>
                    <h6 className="card-title" style={{ marginBottom: '0px', color: '#231f20', fontSize: '2rem' }}><b>{ticket.sub_event.name}</b> </h6>
                    <p className="card-text" style={{ marginBottom: '0px', fontSize: '14px', color: '#231f20' }}>{ticket.sub_event.main_event.church.name} &#x2022; {ticket.sub_event.room.name}</p>
                    
                    {extras && exclusive === "aogrun" ? (
                      <div style={{ marginBottom: '0px', fontSize: '12px', color: '#625f61' }}>
                      Notes:<br />- Type <strong>{capitalizeFirstLetter(extras.type)}</strong><br />- Jersey <strong>{extras.size}</strong><br />- Pickup on <strong>{extras.pickup}</strong>
                      <br />Schedule: SCC: 11-12 Feb, PCM 12 Feb, MCC 12 Feb, SCCWD 14-16 Feb
                      </div> 
                    ) : null}
                    {extras && exclusive === "gmsrunmanado" ? (
                      <div style={{ marginBottom: '0px', fontSize: '12px', color: '#625f61' }}>
                      Notes:<br />- Jersey <strong>{extras.size}</strong><br />
                      Pickup on <br /><strong>Racekit di GMS Manado, Jl. Wolter Monginsidi No.1, Kompleks Bahumall,<br />
                      Samping Apartemen Lagoon/Hotel Best Western<br />
                      Penukaran Racekit tanggal 1-3 November 2023<br />
                      Jam penukaran mulai 10.00 sampai 20.00 WITA
                      </strong>
                      </div> 
                    ) : null}
                    {extras && exclusive === "gmsrunbalikpapan" ? (
                      <div style={{ marginBottom: '0px', fontSize: '12px', color: '#625f61' }}>
                      Notes:<br />
                      - Type <strong>{capitalizeFirstLetter(extras.type)}</strong><br />
                      - Jersey <strong>{extras.size}</strong><br />
                      Racepack Pickup <br />
                      <strong>
                        Racekit di GMS Balikpapan, Jl. Mayjend Sutoyo Rt.44 No.32<br />
                        Penukaran Racekit tanggal 04 Agustus 2024<br />
                        Jam penukaran mulai 10.00 sampai 17.00 WITA
                      </strong>
                      </div> 
                    ) : null}
                    {extras && exclusive === "aogfindingmajor" ? (
                      <div style={{ marginBottom: '0px', fontSize: '12px', color: '#625f61' }}>
                      Notes:<br />- Kelas: <strong>{extras.kelas}</strong><br />
                      </div> 
                    ) : null}
					          {extras && exclusive === "checklistinterpreter" ? (
                      <div style={{ marginBottom: '0px', fontSize: '12px', color: '#625f61' }}>
                      Notes:<br />- Interpreter: <strong>{(extras.interpreter === 0 ? 'Tidak' : 'Ya')}</strong><br />
                      </div> 
                    ) : null}
                    {extras && (exclusive === "aogbootcampyouth" || exclusive === "aogbootcampteen") ? (
                      <div style={{ marginBottom: '0px', fontSize: '12px', color: '#625f61' }}>
                        Notes:<br />
                        - Nama: {extras.name}<br />
                        - Jenis Kelamin: {extras.gender}<br />
                        - Nomor telepon: {extras.phoneNumber} {extras.parentPhone ? "(Nomor telepon orang tua)" : ""} <br />
                        {extras.isCG ? "- Kode CG: " + extras.cgCode : "- Alamat: " + extras.address} <br />
                        {extras.isCG ? <> - Posisi di CG: {extras.positionCG} <br/></> : ""}
                        - Alergi dan Riwayat penyakit: {extras.medicalCondition}<br />
                      </div> 
                    ) : null}

                    {extras && exclusive === "christmascelebration" && parseInt(extras.forvip) === 1 ? (
                      <div style={{ marginBottom: '0px', fontSize: '12px', color: '#625f61' }}>
                        Catatan: {extras.catatan}
                      </div> 
                    ) : null}
                    </div>
                    <div className="col-2">
                      <div style={{ marginBottom: '0px', fontSize: '3rem', color: '#231f20', textAlign: 'right' }}>{ticket.quantity}</div>
                      <div style={{ marginBottom: '0px', fontSize: '10px', color: '#231f20', textAlign: 'right'  }}>{l10n['ticket'][language]}</div>
                    </div>
                    </div>
                    <div><hr /></div>
                    <div className="row">
                      <div className="col-3">
                      {ticket.paid_status ? (
                        <>
                          {ticket.paid_status==='WAITING' ? (
                            <button onClick={props.deleteTicket.bind(this, ticket)} style={btnDeleteStyle}>{l10n['cancelTicket'][language]}</button>
                          ) : null}
                        </>
                        ) : <button onClick={props.deleteTicket.bind(this, ticket)} style={btnDeleteStyle}>{l10n['cancelTicket'][language]}</button>}
                      </div>
                      <div className="col-9">
                        {ticket.paid_status ? (
                          <>
                          {ticket.paid_status==='WAITING' ? (
                            <button onClick={props.processPaymentTicket.bind(this, ticket)} style={btnProcessStyle}>
                            Lanjutkan Pembayaran
                            </button>
                          ) : ticket.paid_status==='PAID' ? (
                            <div style={infoPaymentStyle}>LUNAS</div>
                          ) : null }
                          </>
                        ) : null}
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Collapse>
          </div>
          )
          }
        )}
      </Card>
      {(props.tickets.length)>1 ? (
        <>
          {props.tickets.sort((a, b) => { return a.sub_event.start_date > b.sub_event.start_date }).map((ticket, i) => (
          <Accordion.Toggle as={Card.Header} key={`ticketbottom-${i}`} eventKey={`${i}`} style={ticketBottomStyle}>
            <p style={ticketHeaderTextStyle}>#{i+1}</p>
          </Accordion.Toggle>
          ))}
        </>
      ) : null}
    </Accordion>
  )
}

// Styles
const cardStyle = {
    display: "flex",
    flexDirection: "column",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    border: "none",
};

const accordionStyle = {
  backgroundColor: '#1d2434',
  borderTopLeftRadius: '15px',
  borderTopRightRadius: '15px',
}

const cardBodyStyle = {
  backgroundColor: '#423f40',
  color: '#fff',
}
const cardInnerStyle = {
  backgroundColor: '#fff',
  color: '#fff',
  marginLeft: '6px',
  marginRight: '6px',
  marginTop: '12px',
  marginBottom: '12px',
  borderRadius: '6px',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '12px',
  paddingBottom: '12px',
}


const badgeStyle = {
    position: "absolute",
    left: 12,
    marginTop: "-5px",
};

const ticketHeaderStyle = {
  height: '45px',
  backgroundColor: '#1d2434',
  textAlign: 'center'
}

const ticketBottomStyle = {
  height: '25px',
  backgroundColor: '#1d2434',
  textAlign: 'center',
  width: '20%',
  display: 'inline-block',
  fontSize: '10px',
}

const ticketHeaderTextStyle = {
    fontSize: "14px",
    fontWeight: "600",
    color: "#fff",
    marginBottom: "auto",
};

const btnDeleteStyle = {
  height: '35px',
  border: 'none',
  borderRadius: '2px',
  background: '#DC3545',
  color: '#fff',
  cursor: 'pointer',
  fontSize: '14px',
  textTransform: 'uppercase',
  display: 'block'
}
const btnProcessStyle = {
  height: '35px',
  border: 'none',
  borderRadius: '2px',
  background: '#4CAF50',
  color: '#fff',
  cursor: 'pointer',
  fontSize: '14px',
  textTransform: 'uppercase',
  textAlign: 'center',
  display: 'block',
  width: '100%'
}
const infoPaymentStyle = {
  color: '#4CAF50',
  fontSize: '2rem',
  textTransform: 'uppercase',
  textAlign: 'right',
  display: 'block'
}
